import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CadrePhotoPerso = (props) => {
  const data = useStaticQuery(graphql`
    {
      photoencadree: file(relativePath: { eq: "photo-encadree.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 120)
        }
      }
      bratan20: file(relativePath: { eq: "cadres-photos/BRATAN20.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      major2: file(relativePath: { eq: "cadres-photos/MAJOR2.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      plana3030: file(relativePath: { eq: "cadres-photos/PLANA3030.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      oxyton: file(relativePath: { eq: "cadres-photos/OXYTON.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      ivao40: file(relativePath: { eq: "cadres-photos/IVAO40.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      bradford: file(relativePath: { eq: "cadres-photos/BRADFORD.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      city: file(relativePath: { eq: "cadres-photos/CITY.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      babylon: file(relativePath: { eq: "cadres-photos/BABYLON.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 148)
        }
      }
      mv3: file(relativePath: { eq: "cadres-photos/multivues-3-fenetres.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
      mv4: file(relativePath: { eq: "cadres-photos/multivues-4-fenetres.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 148)
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Cadre photo personnalisé" description="Cadre photo personnalisé, caisse américaine pour photo" canonical={`${process.env.GATSBY_URL_ACTUEL}${props.location.pathname}`} />
      <Layout banner={false} location={props.location}>
        <div className="container">
          <h1>Cadre pour photo personnalisé</h1>
          <div className="columns is-centered">
            <div className="column">
              <div className="columns">
                <div className="column is-narrow">
                  <GatsbyImage image={getImage(data.photoencadree)} alt="Cadre pour photo personnalisé" />
                </div>
                <div className="column">
                  <p>Avec notre service <Link to="/tirage-photo-encadre/">tirage photo encadrée</Link>, rien de plus facile pour <strong>encadrer une photo</strong> !</p>
                  <p>La première étape est de choisir le nombre de photos de votre cadre. En effet vous pouvez-choisir un cadre simple ou <strong>multivue</strong>.</p>
                  <p>Si vous choisissez multivue, le choix d’un passe partout est obligatoire afin de positionner vos photos dans le cadre avec un espace entre les photos, plus esthétique.</p>
                </div>
              </div>
              
              <p>Ensuite vous sélectionnez vos photos. Vous pouvez aussi les recadrer.</p>
              <p>Enfin vous sélectionnez le type de support de votre <strong>tirage photo</strong> : papier qualité professionnelle 260g, papier qualité haut de gamme <strong>BARYTA 310g</strong> ou <strong>sublimation sur chromaluxe</strong></p>
              <p>En fonction de la résolution de votre photo, le site vous indique les dimensions maximum du cadre pour que votre photo reste nette.</p>
              <p>Après avoir sélectionné vos dimensions, le site propose un choix de cadres et caisses américaines adaptées, vous n’avez plus qu’à choisir la protection (verre, plexiglas) et commander !</p>
              <div className="has-text-centered mt-5"><Link to="/tirage-photo-encadre/" className="button is-info is-large">Découvrir le service tirage photo encadrée</Link></div>
            </div>
          </div>
        </div>
        <div style={{background: "#9f8f7e", padding: "2rem 0", margin: "2rem -2rem"}}>
          <div className="container">
            <div className="h2" style={{color: "#fff", textShadow: "2px 2px 5px #777", textAlign: "center"}}>Cadres photo personnalisés populaires</div>
            <div className="is-flex" style={{textAlign: "center", justifyContent: "space-evenly", flexWrap: "wrap"}}>
              <Link to="/cadre/Cadre-bois-BRATAN-20-Noir-89/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Cadre bois noir</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>BRATAN 20</div>
                <GatsbyImage image={getImage(data.bratan20)} alt="Cadre photo 40x60 bois noir" />
              </Link>
              <Link to="/cadre/Cadre-bois-PLANA-30.30-Blanc-643/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Caisse bois blanc</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>PLANA 30.30</div>
                <GatsbyImage image={getImage(data.plana3030)} alt="Cadre photo 40x60 bois blanc" />
              </Link>
              <Link to="/cadre/Caisse-americaine-MAJOR-2-Noir-1826/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Caisse américaine noire et or</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>MAJOR 2</div>
                <GatsbyImage image={getImage(data.major2)} alt="Caisse américaine photo 40x60 noir or" />
              </Link>
              <Link to="/cadre/Caisse-americaine-OXYTON-Noir-1671/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Caisse américaine noir</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>OXYTON</div>
                <GatsbyImage image={getImage(data.oxyton)} alt="Caisse américaine photo 40x60 bois noir" />
              </Link>
              <Link to="/cadre/Cadre-bois-BRADFORD-Argent-1988/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Caisse bois argent</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>BRADFORD</div>
                <GatsbyImage image={getImage(data.bradford)} alt="Cadre photo 40x60 bois argent" />
              </Link>
              <Link to="/cadre/Cadre-doublo-marie-louise-IVAO-40-Marron-tons-bois-411/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Cadre marron marie louise crème</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>IVAO 40</div>
                <GatsbyImage image={getImage(data.ivao40)} alt="Cadre bois 40x60 marron crème" />
              </Link>
              <Link to="/cadre/Cadre-bois-BABYLON-Naturel-494/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Cadre bois naturel</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>BABYLON</div>
                <GatsbyImage image={getImage(data.babylon)} alt="Cadre photo 40x60 bois naturel" />
              </Link>
              <Link to="/cadre/Cadre-doublo-design-CITY-Orange-1516/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Cadre orange et noir</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>CITY</div>
                <GatsbyImage image={getImage(data.city)} alt="Cadre photo 40x60 bois orange noir" />
              </Link>
              <Link to="/cadre/Cadre-bois-CHAMBORD-029-Or-304/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Cadre multivues or</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>CHAMBORD 029</div>
                <GatsbyImage image={getImage(data.mv3)} alt="Cadre multivues or" />
              </Link>
              <Link to="/cadre/Cadre-bois-ESSENTIA-25.15-Naturel-1655/" className="p-4" style={{color: "#fff", textShadow: "2px 2px 5px #777"}}>
                <div style={{fontWeight: "bold"}}>Cadre multivues noyer</div>
                <div style={{fontSize: "0.8rem", marginBottom: "0.5rem"}}>ESSENTIA 25.15</div>
                <GatsbyImage image={getImage(data.mv4)} alt="Cadre photo multivues bois" />
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <p>Prendre une photographie est un geste que l’artiste, qu’il soit amateur ou professionnel,
          pense, réfléchit. Il voit d’ailleurs au-delà de l’image qu’il capture à travers son objectif. C’est
          pour lui l’occasion de magnifier un paysage, d’en souligner l’activité, mais aussi de sublimer
          un visage et d’en faire jaillir toute la puissance de l’expression de traits, de chaque ride, de
          chaque pli de la peau.</p>
          <p>À l’instant même où le doigt enfonce le déclencheur, l’artiste imagine, visualise le cadre
          photo qui sera le plus à même de révéler toute la force de son œuvre et de l’exposer ainsi
          avec fierté. C’est pourquoi, pouvoir réaliser un <strong>cadre photo personnalisé</strong> constitue une
          solution idéale afin d&#39;être pleinement satisfait par le rendu final. Il n’y a rien en effet de plus
          décevant que de voir son œuvre perdre de son éclat par l’effet seul d’un encadrement raté
          qui viendrait gâcher toute la teneur du propos.</p>
          <p>Faites réaliser un magnifique cadre photo personnalisé en confiant votre travail au savoir-
          faire et au professionnalisme de Encadrement sur mesure. Pour des projets professionnels
          ou plus personnels, venez découvrir le fonctionnement de notre service qui vous permettra
          d’obtenir des cadres photo personnalisés d&#39;exception.</p>
          <p>Le cadre photo personnalisé constitue enfin un formidable cadeau à faire à ceux que vous
          aimez, il permet de conserver en mémoire les souvenirs de jours heureux.</p>
          <h2>Le cadre photo personnalisé : la solution parfaite pour un cadeau réussi !</h2>
          <p><strong>Le cadre photo personnalisé</strong> constitue une solution parfaite dans l&#39;optique de réaliser des
          encadrements élégants et originaux.
          Que vous souhaitiez réaliser des projets personnels ou bien professionnels, le cadre photo
          personnalisé est simple et le commander ne prendra que quelques instants.</p>
          <p>La boutique de l’Encadrement sur mesure présente de nombreux types et formats de cadres
          de manière à ce que vous disposiez d’un large choix et que ce dernier ne se fasse pas par
          défaut auquel cas vous pourriez être déçu du résultat. Choisissez ainsi parmi nos modèles
          de cadres en aluminium, en bois, nos caisses américaines, nos cadres avec Marie-Louise,
          nos cadres vitrines, nos cadres entre deux verres, ou encore nos cadres de la gamme
          astrale exclusifs.</p>
          <p>N&#39;hésitez pas en outre à importer vos œuvres afin de vous faire une idée précise du rendu
          final.</p>
          <h2>Créez votre cadre photo personnalisé en seulement quelques clics</h2>
          <p>La création d’un cadre photo personnalisé ne requiert que quelques clics. Prenez ainsi le
          temps de choisir avec attention et rigueur votre encadrement. Suivez nos conseils étape par
          étape et obtenez rapidement un cadre photo personnalisé.</p>
          <ol className="ml-5">
            <li><p>Rendez-vous d’abord à la rubrique &quot;<strong>tirage photo</strong>&quot; puis indiquez le nombre de
            photographies que vous souhaitez encadrer.</p>
            <p>Sélectionnez alors la photographie que vous aimeriez voir dans votre <strong>cadre photo
            personnalisé</strong> et recadrez-les au besoin. Ne négligez pas cette étape, car comme
            vous le savez, le cadrage est une donnée essentielle pour un encadrement réussi.
            Sans cadrage, le propos risque de perdre tout son sens.</p></li>
            <li><p>Choisissez ensuite le type de support sur lequel sera réalisé votre tirage
            photographique. Nous vous proposons plusieurs catégories de supports : soit sur du
            papier de qualité haut de gamme BARYTA 310 g, papier, soit sur du papier de
            qualité professionnelle de 260 g, sinon optez pour la sublimation sur chromaluxe.</p>
            <p>Cette dernière solution sur plaque rigide permet d’obtenir un résultat magnifique de
            très haute qualité. La finesse de l’image est supérieure à celle que vous pourriez
            avoir avec un tirage argentique. Les moindres détails de votre photographie y seront
            restitués, les textures par ailleurs sembleront prendre vie.</p>
            <p>Concernant les dimensions et la résolution de votre photographie, ne craignez rien !
            Notre site calcule les dimensions maximales de votre cadre photo personnalisé en
            fonction de la résolution de votre photographie. Ainsi, celle-ci demeurera nette, vous
            ne souffrirez d&#39;aucune mauvaise surprise lorsque vous récupérez votre commande.</p></li>
            <li>Renseignez enfin les dimensions souhaitées en fonction des disponibilités de notre
            boutique en ligne. Ajoutez enfin une protection telle qu’un verre ou du plexiglas afin
            de protéger votre œuvre.</li>
          </ol>
          <h2>Pourquoi choisir notre service de création de cadres photo personnalisés ?</h2>
          <p>Encadrement sur mesure est une entreprise composée de professionnels qui ont à cœur de
          vous offrir des cadres sur mesure d’exception qui correspondront parfaitement à vos
          attentes ainsi qu’à vos besoins. Nous appartenons par ailleurs au groupe PFC des
          professionnels du cadre, preuve de la qualité de nos services. Confiez-nous ainsi les yeux
          fermés vos projets afin que nous participions à leur mise en valeur.</p>
          <p>Les découpes de nos cadres sont faites au sein de nos ateliers de Besançon. De fait,
          contrairement aux produits que vous pourriez trouver en grande surface, nous sommes en
          mesure de répondre à toutes vos questions et d’effectuer les changements nécessaires afin
          de vous garantir un résultat d’une extrême précision.</p>
          <p>Sachez en outre que vous bénéficiez de 10% de réduction dès 250 euros d’achat et de 20%
          de réduction dès 450 euros d’achat sur l’ensemble de notre boutique.</p>
          <p>Dès que votre commande est passée, disposez enfin d’un délai de commande de huit jours
          ouvrés sauf si vous choisissez l’option EXPRESS qui vous permet l’expédition de votre
          cadre photo personnalisé le jour ouvré suivant.</p>
        </div>
      </Layout>
    </>
  )
}

CadrePhotoPerso.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadrePhotoPerso
